.navbar {
    padding: .5rem 0rem;
}

.navbar-light .navbar-nav .nav-link {
    color: $color-main;
    font-weight: bold;
    text-transform: uppercase;
}

.mr-auto,
.mx-auto {
    // margin-left: auto !important;
    // margin-right: inherit !important;
}