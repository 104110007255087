
// .single-page
.single-page {
    h1.post-title {
        font-size: $font-size-h1;
        margin-top: 0;
    }

    .post-content {
        margin-bottom: 20px;
        h2 {
            font-size: $font-size-h2;
        }
        p {
            padding-left: 0;
            padding-right: 0;
            margin: 0;
        }
    }

    .post-others {

    }

    .post-tag-list {
        margin-top: 20px;
    }

    .single-slider-photos {
        margin-bottom: 30px;
    }
}