body {
    margin: 0;
    font-family: Roboto, Arial,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: $body-color-background;
    color: $color-main;
}

a {
    color: $color-a;

    &:hover {
        color: $color-a-hover;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.logo {
    .navbar-brand {
        img {
            max-height: 60px;
        }
    }
}

.socials-list-icons {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        display: inline;
        a {
            padding: 3px 10px;
            border: 1px solid;

            span {
                display: none;
            }
        }
    }
}

.social-linked {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        display: inline;
        a {
            padding: 3px 10px;
            border: 1px solid;
        }
    }
}

// scrollup
.scrollup {
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    width: 39px;
    height: 39px;
    border-radius: 5px;
    font-size: 20px;
    position: fixed;
    bottom: 30px;
    right: 20px;
    border: 1px solid $scrollup-color;
    display: none;
    background: $scrollup-bg;

    .icon-up-open:before {
        content: '\e805';
    }

    &:hover {
        background: $scrollup-hover;
        border-color: $scrollup-color;
        a {
            color: $scrollup-color;
        }
    }

    > a {
        position: relative;
        top: 0px;
        color: $scrollup-color;
    }
}

// [class^="icon-"]:before, [class*=" icon-"]:before {
//     font-family: "porta";
//     font-style: normal;
//     font-weight: normal;
//     speak: none;
//     display: inline-block;
//     text-decoration: inherit;
//     text-align: center;
//     /* opacity: .8; */
//     font-variant: normal;
//     text-transform: none;
//     /* font-size: 120%; */
//     /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
// }

// main-content
.main-content {
    margin-top: 20px;
    margin-bottom: 20px;
}