.post-header {
    h1 {
        margin-left: 0;
        margin-right: 0;
    }
    .post-title {}
}

.post-content {

}