// header
.header {
    // background: $header-bg !important;
    .navbar {
        margin-bottom:0;
        min-height:70px;
    }
}

.top-header-banner {
    display: block;
    width: 100%;

    .logo,
    .banner-top
    {
        display: inline-flex;
    }
    .logo {}
    .banner-top {
        float: right;
        img {
            max-height: 70px;
        }
    }
}

.navigation {
    background: $main-navigation-bg;
    margin-top: 10px;
    color: $main-navigation-color;
    .navbar.navbar-light {
        padding: 5px 0;

        .navbar-nav {
            a.nav-link {
                color: $main-navigation-color;
            }
        }
    }
}