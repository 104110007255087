// paginator
.paginator {
    padding: 0;
    margin: 15px 0;
    li {
        list-style: none;
        display: inline-block;

        a {
            padding: 5px 10px;
        }

        &.active {
            a {
                background: #e2e2e2;
            }
        }
    }
}
