
// footer-section
.footer-section {
    background: $footer-section-bg;
    padding-top: 20px;

    .list-unstyled {
        li {
            i.fa {
                font-size: 16px;
            }
        }
    }

    .group-info {
        h6 {
            text-transform: uppercase;
            font-size: 17px;
        }
    }

    .footer-menus {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            a {}
        }
    }
}

// footer section
.footer-copyright {
    min-height: 40px;
    line-height: 40px;
    background: $footer-bg;
    color: #fff;
    font-size: 13px;
    p {
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .socials-list-icons {
        float: right;
        li {
            a {
                color: #fff;
                border: 1px solid #fff;
            }
        }
    }
}