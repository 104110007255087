.swiper-container {
    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 20px;
        height: 25px;
        margin-top: -22px;
        z-index: 10;
        background-size: 15px 30px;
        background-position: center;
        background-repeat: no-repeat;

        display: none;
    }

    &:hover {
        .swiper-button-next,
        .swiper-button-prev {
            display: block;
        }
    }
}

section {
    margin: 60px 0;
    .section-title {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 15px;

        h2 {
            font-size: 25px;
        }
    }

    .section-note {
        text-align: center;
        margin-bottom: 20px;
    }
}

.service-section {
    .title {
        text-align: center;
        h2 {
            font-size: $home-service-section-title-font-size;
            text-transform: $home-service-section-title-font-transform;
        }
    }

    .circle-border {
        overflow: hidden;

        img {
            max-width: 100%;
            transition: all .3s ease 0s;
            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .article-thumb {
        margin-bottom: 10px;
    }

    .article-content {
        .article-title {
            font-size: 15px;
            font-weight: bold;
        }
    }

    .home-cat-item {
        margin-bottom: 10px;
        .circle-border {
            margin-bottom: 10px;
        }

        .article-title {
            font-size: 15px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.testimonials {
    .article {
        .article-thumb {
            box-shadow: none;
            width: 100px;
            height: 100px;
            margin: 0;

            max-width: 100px;
            float: left;
            margin-right: 15px;

            box-shadow: 0 0 33px 4px rgba(0,0,0,.07);
            border-radius: 50%;
            border: 1px solid #ffcc2f;
            position: relative;
            overflow: hidden;

            img {
                max-width: 100%;
                transition: all .3s ease 0s;

                &:hover {
                    transform: scale(1.1);
                }
            }

            &.single-trainer {
                transition: all .3s ease 0s;
            }
        }
    }

    .testimonial-fa-quote {
        .fa {
            font-size: 12px;
            margin: 0 5px;
        }
    }
}

// Slide show
.da-slide p,
.da-slide ol,
.da-slide ul 
{
    width: 45%;
    padding: 0px;
    top: 230px;
    color: #fff;
    height: 90px;
    overflow: hidden;
    font-weight: 400;
}

.da-slide h2,
.da-slide h4,
.da-slide p,
.da-slide ul,
.da-slide ol,
.da-slide .da-link,
.da-slide .da-img {
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
    left: 110%;
}

.da-slide-toleft p,
.da-slide-toleft ol,
.da-slide-toleft ul
{
    -webkit-animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
    -moz-animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
    -o-animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
    -ms-animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
    animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
}

.da-slide-fromright p,
.da-slide-fromright ol,
.da-slide-fromright ul {
    -webkit-animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
    -moz-animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
    -o-animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
    -ms-animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
    animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
}