$colors-blue: #007bff;
$colors-indigo: #6610f2;
$colors-purple: #6f42c1;
$colors-pink: #e83e8c;
$colors-red: #dc3545;
$colors-orange: #fd7e14;
$colors-yellow: #ffc107;
$colors-green: #28a745;
$colors-teal: #20c997;
$colors-cyan: #17a2b8;
$colors-white: #fff;
$colors-gray: #6c757d;
$colors-gray-dark: #343a40;
$colors-primary: #007bff;
$colors-secondary: #6c757d;
$colors-success: #28a745;
$colors-info: #17a2b8;
$colors-warning: #f1ac00;
$colors-danger: #dc3545;
$colors-light: #f8f9fa;
$colors-dark: #343a40;
$color-black: #161616;

/* Colors */
$body-color-background: #ffffff;
$footer-section-background: #ffffff;
$color-main: #181A1C;
$color-a: #181A1C;
$color-a-hover: $colors-warning;

$color-border: #dddddd;
$color-border-hover: #dddddd;

$color-sticky-menu: #eeeeee;

$menu-color-hover: #ffb607;

$background-color-main: #181A1C;