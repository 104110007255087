.support-online {
    position: fixed;
    z-index: 5;
    left: 0;
    bottom: 0;

    .kenit-alo-circle {
        background-color: transparent;
        border-radius: 100%;
        border: 2px solid rgba(30,30,30,.4);
        opacity: .1;
        border-color: #0089b9;
        opacity: .5;
    }

    .kenit-alo-circle,
    .kenit-alo-circle-fill {
        width: 40px;
        height: 40px;
        top: 0;
        position: absolute;
        right: 0;
    }

    a {
        position: relative;
        margin: 10px;
        text-align: left;
        width: 40px;
        height: 40px;
        display: block;
        span{
            display: none;
        }

        &:hover span{
            display: block;
        }

        span {
            border-radius: 2px;
            text-align: center;
            background: #67b634;
            padding: 5px;
            display: none;
            width: 140px;
            margin: 0;
            position: absolute;
            color: #fff;
            z-index: 5;
            top: 5px;
            left: 38px;
            transition: all .2s ease-in-out 0s;
            animation: headerAnimation .7s 1;
        }
    }

    a.call-now i {
        width: 32px;
        height: 32px;
        font-size: 16px;
        top: 4px;
        left: 4px;
    }

    img {
        width: 40px;
        height: 40px;
        background: #43a1f3;
        color: #fff;
        border-radius: 100%;
        font-size: 20px;
        text-align: center;
        line-height: 1.9;
        position: relative;
        z-index: 5;
    }

    i {
        width: 40px;
        height: 40px;
        background: #43a1f3;
        color: #fff;
        border-radius: 100%;
        font-size: 20px;
        text-align: center;
        line-height: 1.9;
        position: relative;
        z-index: 5;
    }
}