$article-title-size: 15px;
.grid-articles {
    .article {
        margin-bottom: 25px;
        display: inline-block;
        width: 100%;

        .article-thumb {
            // float: left;
            // max-width: 30%;
            // margin-right: 20px;
            margin-bottom: 10px;
        }

        .article-content {
            .article-title {
                font-size: $article-title-size;
                font-weight: 600;
            }
        }
    }
}

.list-articles {    
    .article {
        margin-bottom: 25px;
        display: inline-block;
        width: 100%;

        .article-thumb {
            // float: left;
            // max-width: 30%;
            // margin-right: 20px;
        }

        .article-content {
            .article-title {
                font-size: $article-title-size;
                font-weight: 600;
            }
        }
    }    
}

.article {
    .article-thumb {
        a {
            img {}
        }
    }

    .article-content {
        .blog-title {
            font-size: 17px;
            line-height: 18px;
            margin-top: 0;

            a {
                font-weight: 700;
                color: $color-main;
            }
        }

        p {
            // margin: 0;
            // padding: 0;
            // margin-bottom: 5px;
        }

        .read-more {
            a {
                font-style: italic;
                font-size: 14px;
            }
        }
    }
}


.articles-related {
    margin-top: 20px;

    .related-header {
        text-transform: uppercase;
        font-size: $articles-related-header-size;
        font-weight: bold;
    }
}


.post-others {
    .post-tag-list {
        ul {
            margin: 0;
            padding: 0;
            
            li {
                list-style: none;
                display: contents;

                i.fa-tags {
                    font-size: 18px;
                }
                a {
                    font-size: 15px;
                    padding: 3px 7px;
                    background: $tag-item-background;
                    color: $tag-item-color;
                }
            }
        }
    }
}