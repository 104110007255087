.sidebar{
    .sidebar-group {
        .sidebar-header {
            display: inline-block;
            width: 100%;
            text-transform: uppercase;
            background: $sidebar-header-background;
            color: $sidebar-header-color;
            margin-bottom: 10px;

            .sb-header {
                font-size: $sidebar-header-size;
                margin: 0;
                padding: 5px 10px;
                line-height: $sidebar-header-line-height;
            }
        }
        margin-bottom: 20px;
        
        &.make-me-sticky {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
              
            padding: 0 15px;
        }

        .sidebar-group-item {
            margin-bottom: 10px;
            width: 100%;
        }
    }

    ul.list-address {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin-bottom: 5px;

            i.fa {
                font-weight: bold;
                margin-right: 5px;
            }
        }
    }

    .list-articles {
        .article {
            margin-bottom: 15px;
            .article-content {
                .article-title {
                    font-size: $sidebar-article-title-size;
                }

                .article-title,
                a {
                    font-size: $sidebar-article-title-size;
                    font-weight: normal;
                }
            }
        }
    }


    .modal {
        .modal-header {
            .fa-heart {
                color: #ff0000;
            }
        }
    }
    // purchase-panel
    .purchase-panel {
        border: 1px solid #ddd;
        padding: 15px;
        text-align: center;
        margin: 15px 0;

        .purchase-free {
            font-size: 25px;
            margin-bottom: 10px;
            label{
                span {
                    font-weight: bold;
                }
                margin: 0;
            }
        }

        .purchase-group {
            .purchase-download-btn {
                margin-bottom: 20px;
            }
            .purchase-btn {

            }
        }
    }

    .payment-infomation {
        font-size: 13px;
        h5 {}
        p {margin-bottom: 0;}
        ul {
            margin-bottom: 5px;
            li {

            }
        }
    }

    .download-form-infomation {
        .download-form-item {
            display: block;
            height: 35px;
            label {
                float: left;
                min-width: 70px;
                margin-right: 10px;
            }
        }
    }
}