
@media (min-width: 1200px)
{
    .container {
        max-width: 1200px;
    }
}

@media (max-width: 991px) and (min-width: 768px)
{
    .row-properties-list{
        .col-md-4 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

@media (max-width: 991px)
{
    .articles-related{
        .list-articles {
            .article{
                .article-thumb {
                    margin-bottom: 10px;
                }
            }
        }
    }
}