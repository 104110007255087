.comment-layout {
    .comment-header {
        text-transform: uppercase;
        margin-top: 15px;
        .comment-title {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .comment-content {
        form {
            .form-group {
                .input-group-prepend {
                    float: left;
                    // margin-right: 10px;
                }
            }
        }
    }

    .comment-list {
        p.body{
            margin-bottom: 0;
        }
    }

    form {
        .form-group {
            
        }
    }
}

.errorForm {
    .errorMsg {
        border-color: #ff0000;
    }
}