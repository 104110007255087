$breadcrumbs-size: 14px;

.breadcrumbs {
    ul.breadcrumb {
        .fa-home {
            font-size: 18px;
        }
        padding: .55rem 0rem;
        border:none;
        border-radius: 0;
        font-size: $breadcrumbs-size;
        background: none;

        li {
            margin-right: 5px;
            .fa-angle-right{
                margin-right: 5px;
            }

            &:first-child {
                padding-left: 0;
                margin-right: 5px;
                a {
                    margin-left: 0;
                }
            }
            a {
                margin-right: 5px;
                font-size: $breadcrumbs-size;
            }
        }
    }
}