.assets-group-detail {
    border-left: 1px solid #ddd;
    padding-left: 15px;

    .group-item {
        display: block;
        text-align: center;
    }

    .group-price {
        span {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .group-sale {}

    .group-updated {

    }

    .group-details {
        .read-more {
            display: block;
            border: 1px solid #ddd;
            padding: 5px;

            a {
                font-size: 13px;
                font-style: normal;
            }

            &:hover {
                background-color: #efefef;
            }
        }
    }
}

.sidebar-assets {
    .article-thumb {
        margin-bottom: 15px;
    }
    
    .assets-group-detail {
        border-left: none;
        padding-left: none;
    }
}