.single-properties-group{
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    
    .summarys-group {
        margin-bottom: 5px;
        span{
            font-weight: 600;
        }
    }

    margin-bottom: 20px;
    .fa {
        margin-right: 5px;
    }

    ul{
        margin: 0;
        padding: 0;
        li{
            list-style: none;
        }
    }
    .group-price-list{
        display: flex;
        margin-bottom: 5px;

        .price-list{
            li{display: inline; margin-right: 20px;}
            .old-price{
                font-size: 16px;
                font-weight: bold;
                color: #ff0000;
            }
        }

        .district-list{
            margin-left: 30px;
        }
    }

    .properties-group{
        .row-properties-list{
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-right: -5px;
            margin-left: -5px;
        }

        .properties-list{            
            .realestate-item {
                margin-bottom: 5px;
                padding-left: 5px;
                padding-right: 5px;
                
                span {
                    font-weight: bold;
                    min-height: 25px;
                }

                .old-price{
                    background: #f74a27;
                    color: #fff;
                    padding: 3px 5px;
                    border-radius: 4px;
                }
            }
        }
    }
}