/* Background */
$title-color: $background-color-main;
$font-size: 14px;
$bg-color-header: $colors-white;
$header-bg: #f1f1f1;
$header-color: #f1f1f1;
$header-hover: #f1f1f1;

$main-navigation-bg: $background-color-main;
$main-navigation-color: $colors-white;

$bg-color-footer: $background-color-main;
$bg-color-sticky-menu: $color-sticky-menu;
$font-size-h1: 22px;
$font-size-h2: 20px;
$font-size-h3: 18px;
$font-size-h4: 16px;

$footer-bg: $background-color-main;
$footer-section-bg: #f1f1f1;
$footer-section-color: $footer-section-background;
$footer-section-hover: $footer-section-background;

$btn-bg-primary: $background-color-main;
$btn-bg-primary-hover: $colors-warning;

$breadcrumb-bg: $background-color-main;
$breadcrumb-color: $colors-white;

$scrollup-bg: $background-color-main;
$scrollup-color: $colors-white;
$scrollup-hover: $menu-color-hover;

// For tags
// detail page
$tag-item-background: $background-color-main;
$tag-item-color: $colors-white;

// sidebar
$sidebar-article-title-size: 14px;
$sidebar-header-size: 16px;
$sidebar-header-line-height:28px;
$sidebar-header-background: $background-color-main;
$sidebar-header-color: $colors-white;

// articles-related
$articles-related-header-size: 18px;

// Home layout
$home-service-section-title-font-size: 22px;
$home-service-section-title-font-transform: uppercase;